import FireCarousel from "./Components/FireCarousel";
import { SwiperCard } from "./Components/SwiperCard";

import FireLayout from "./Layouts/FireLayout";
import { CategoryCard } from "./Components/Cards";

function Index() {
    const carouselData = [
        {
            image: "https://www.vebingo.com/image/cachewebp/catalog/ana-slider/2.%C3%96rnek%203%20Ay%20Taksit%20imkan%C4%B1-1500x550.webp",
        },
        {
            image: "https://www.vebingo.com/image/cachewebp/catalog/ana-slider/HED%C4%B0YE%20%C3%87ANTA_-1500x550.webp",
        },
        {
            image: "https://www.vebingo.com/image/cachewebp/catalog/ana-slider/Lenovo%20V14%20G4-1500x550.webp",
        },
        {
            image: "https://www.vebingo.com/image/cachewebp/catalog/ana-slider/Evercool%20C80%20Hava%20So%C4%9Futmal%C4%B1%20Cpu%20Fan-1500x550.webp",
        },
        {
            image: "https://www.vebingo.com/image/cachewebp/catalog/ana-slider/AlphaPro%20Twinmos%20%20SSD-1500x550.webp",
        },
        {
            image: "https://www.vebingo.com/image/cachewebp/catalog/ana-slider/MX360%20Kasa-1500x550.webp",
        },
        {
            image: "https://www.vebingo.com/image/cachewebp/catalog/ana-slider/Cruc%C4%B1al%201TB%20SSD-1500x550.webp",
        },
        {
            image: "https://www.vebingo.com/image/cachewebp/catalog/ana-slider/Dell%20SE2422H%20Monit%C3%B6r-1500x550.webp",
        },
        {
            image: "https://www.vebingo.com/image/cachewebp/catalog/ana-slider/Lenovo%20V15%20G3%20IAP%2082TT00A5TX-1500x550.webp",
        },
        {
            image: "https://www.vebingo.com/image/cachewebp/catalog/ana-slider/Vivobook%20Asus%2015%20Notebook-1500x550.webp",
        },
        {
            image: "https://www.vebingo.com/image/cachewebp/catalog/ana-slider/Think%20Centre%20VTXMasa%C3%BCst%C3%BC%20Bilgisayar_-1500x550.webp",
        },
        {
            image: "https://www.vebingo.com/image/cachewebp/catalog/ana-slider/Cooler%20Master%20CMP510%20Kasa-1500x550.webp",
        },
        {
            image: "https://www.vebingo.com/image/cachewebp/catalog/ana-slider/Zalman%2010x-1500x550.webp",
        },
        {
            image: "https://www.vebingo.com/image/cachewebp/catalog/ana-slider/Lenovo%20V15%2016Gb-1500x550.webp",
        },
    ];

    const mobileCarouselData = [
        {
            image: "https://www.binspired.com/cdn/shop/files/Binspired_A_Week_in_New_York_Banner_Device.jpg?v=1649766807&width=640",
            href: "/category",
        },
        {
            image: "https://www.binspired.com/cdn/shop/files/Binspired_A_Week_in_New_York_Banner_Device.jpg?v=1649766807&width=640",
            href: "/category",
        },
    ];

    const iconicCards = [
        {
            image: "https://www.binspired.com/cdn/shop/products/BinspiredJeanMichelBasquiatPortrait50x70cmArtPrintPoster.jpg?v=1662277817&width=533",
            title: "Iconic Jimi Hendrix Collage Portrait",
            href: "/category",
            subtitle: "Art Print",
            sidePriceText: "başlangıç fiyat",
            price: "$36.00",
        },
        {
            image: "https://www.binspired.com/cdn/shop/products/BinspiredJimiHendrixPortrait50x70cmArtPrintPoster.jpg?v=1661812828&width=533",
            title: "Iconic Bob Dylan Collage Portrait",
            href: "/category",
            subtitle: "Art Print",
            sidePriceText: "başlangıç fiyat",
            price: "$200",
        },
        {
            image: "https://www.binspired.com/cdn/shop/products/BinspiredBradleyCooperPortrait50x70cmArtPrintPoster.jpg?v=1662366704&width=533",
            title: "Card Title 3",
            href: "/category",
            subtitle: "Card Subtitle 3",
            sidePriceText: "başlangıç fiyat",
            price: "$300",
        },
        {
            image: "https://www.binspired.com/cdn/shop/products/BinspiredJohnnyCashPortrait50x70cmArtPrintPoster.jpg?v=1662390207&width=533",
            title: "Card Title 4",
            href: "/category",
            subtitle: "Card Subtitle 4",
            sidePriceText: "başlangıç fiyat",
            price: "$400",
        },
    ];

    const quotesCards = [
        {
            image: "https://www.binspired.com/cdn/shop/products/BinspiredItAlwaysSeemsImpossibleUntilIt_sDone50x70cmArtPrintPoster.jpg?v=1672416184&width=533",
            title: "It always seems impossible until its done. ~Nelson Mandela",
            href: "/category",
            subtitle: "Art Print",
            sidePriceText: "başlangıç fiyat",
            price: "$36.00",
        },
        {
            image: "https://www.binspired.com/cdn/shop/products/BinspiredIfOpportunityDoesntKnockBuildADoor50x70cmArtPrintPoster.jpg?v=1669901737&width=533",
            title: "It always seems impossible until its done. ~Nelson Mandela2",
            href: "/category",
            subtitle: "Art Print",
            sidePriceText: "başlangıç fiyat",
            price: "$200",
        },
        {
            image: "https://www.binspired.com/cdn/shop/products/BinspiredWhetherYouThinkYouCanOrThinkYouCantYouAreRight50x70cmArtPrintPoster.jpg?v=1669547757&width=533",
            title: " Whether you think you can or think you cant, youre right. ~Henry Ford",
            href: "/category",
            subtitle: "Card Subtitle 3",
            sidePriceText: "başlangıç fiyat",
            price: "$300",
        },
        {
            image: "https://www.binspired.com/cdn/shop/products/BinspiredBeSoGoodTheyCan_tIgnoreYou50x70cmArtPrintPoster.jpg?v=1669154972&width=533",
            title: "Card Title 4",
            href: "/category",
            subtitle: "Card Subtitle 4",
            sidePriceText: "başlangıç fiyat",
            price: "$400",
        },
    ];

    const coffeCard = [
        {
            image: "https://www.binspired.com/cdn/shop/files/binspired-urban-art-mugs-desktop.jpg?v=1647526365&width=1152",
            mobileImage:
                "https://www.binspired.com/cdn/shop/files/binspired-urban-art-mugs-device.jpg?v=1647526346&width=732",
            href: "/category",
            title: "Urban art mugs",
            buttonText: "Mağaza kupaları",
        },
        {
            image: "https://www.binspired.com/cdn/shop/files/binspired-golden-mug-collection-desktop.jpg?v=1647525620&width=1152",
            mobileImage:
                "https://www.binspired.com/cdn/shop/files/binspired-golden-mug-collection-device.jpg?v=1647525620&width=732",
            href: "/category",
            title: "Urban art mugs",
            buttonText: "Mağaza kupaları",
        },
    ];

    const casesCards = [
        {
            image: "https://www.binspired.com/cdn/shop/products/BinspiredHA_UrbanArtSamsungGalaxys22UltraClearCase.jpg?v=1664873999&width=533",
            title: "HA!",
            href: "/category",
            subtitle: "Samsung Galaxy Case",
            sidePriceText: "başlangıç fiyat",
            price: "$36.00",
        },
        {
            image: "https://www.binspired.com/cdn/shop/products/BinspiredRatchathewiNo1UrbanArtSamsungGalaxys22UltraClearCase.jpg?v=1663863833&width=533",
            title: "Ratchathewi - No1",
            href: "/category",
            subtitle: "Samsung Galaxy Case",
            sidePriceText: "başlangıç fiyat",
            price: "$200",
        },
        {
            image: "https://www.binspired.com/cdn/shop/products/BinspiredGodSaveMyCoffeeSamsungGalaxys22UltraClearCase.jpg?v=1666347720&width=533",
            title: " God Save My Coffee",
            href: "/category",
            subtitle: "Samsung Galaxy Case",
            sidePriceText: "başlangıç fiyat",
            price: "$300",
        },
        {
            image: "https://www.binspired.com/cdn/shop/products/BinspiredGodSaveMyCoffeeiPhone13ProMaxClearCase.jpg?v=1665993256&width=533",
            title: " God Save My Coffee",
            href: "/category",
            subtitle: "İphone Case",
            sidePriceText: "başlangıç fiyat",
            price: "$400",
        },
    ];

    const robhisattwaCard = [
        {
            largeImage:
                "https://www.binspired.com/cdn/shop/files/robhisattwa-mens-tshirt.jpg?v=1647521412&width=1152",
            title: "Urban art mugs",
            buttonText: "Mağaza",
            smallImage: [
                {
                    image: "https://www.binspired.com/cdn/shop/files/robhisattwa-keep-sharp-always.jpg?v=1647523003&width=780",
                },
                {
                    image: "https://www.binspired.com/cdn/shop/files/robhisattwa-tortuga-bay.jpg?v=1647523003&width=780",
                },
                {
                    image: "https://www.binspired.com/cdn/shop/files/robhisattwa-green-mamba.jpg?v=1647523003&width=780",
                },
                {
                    image: "https://www.binspired.com/cdn/shop/files/robhisattwa-kyoto-tigers.jpg?v=1647523003&width=780",
                },
            ],
        },
    ];

    const groveCards = [
        {
            image: "https://www.binspired.com/cdn/shop/files/Binspired_All_Wall_Art.jpg?v=1650351604&width=720",
            title: "Latest Wall Art",
            href: "/category",
            buttonText: "Mağaza",
        },
        {
            image: "https://www.binspired.com/cdn/shop/files/Binspired_All_Tech.jpg?v=1650355459&width=720",
            title: "Latest Tech",
            href: "/category",
            buttonText: "Mağaza",
        },
        {
            image: "https://www.binspired.com/cdn/shop/files/Binspired_All_Apparel.jpg?v=1650364158&width=720",
            title: "Latest Apparel",
            href: "/category",
            buttonText: "Mağaza",
        },
        {
            image: "https://www.binspired.com/cdn/shop/files/Binspired_All_Living.jpg?v=1650352874&width=720",
            title: "Latest Living",
            href: "/category",
            buttonText: "Mağaza",
        },
    ];

    return (
        <FireLayout header="Anasayfa">
            <div className="hidden lg:block">
                <FireCarousel carouselData={carouselData} />
            </div>
            <div className="lg:hidden">
                <SwiperCard
                    type="product"
                    cardData={mobileCarouselData}
                    numberSliderPerView={[1, 1, 1]}
                    isNavigation={false}
                    isPagination={true}
                />
            </div>
            <div className="container">
                <div className="block text-center space-y-5 my-20 mx-4 sm:mx-0">
                    <p className="[font-size:_clamp(3rem,4vw,7rem)] leading-[40px] text-black text-wrap font-black">
                        A WEEK IN NEW YORK
                    </p>
                    <p className="[font-size:_clamp(1.1rem,1.2vw,3rem)] text-black">
                        Show your love to NYC Art every day new
                    </p>
                    <button className="rounded-full bg-black w-fit text-white mt-4 pt-[8px] pr-[20px] pb-[8px] pl-[20px]">
                        Shop the collection
                    </button>
                </div>
                <SwiperCard
                    type="product"
                    cardData={iconicCards}
                    header="İkonik kolaj portreleri"
                    numberSliderPerView={[1, 2, 3]}
                    isNavigation={true}
                    isPagination={false}
                />
                <SwiperCard
                    type="product"
                    cardData={quotesCards}
                    header="Günlük alıntılar"
                    numberSliderPerView={[1, 2, 3]}
                    isNavigation={true}
                    isPagination={false}
                />
                <div className="justify-center">
                    <div className="flex items-center my-4">
                        <h1 className="[font-size:_clamp(1.3rem,1.2vw,2.5rem)] font-bold leading-[28px] border-0 lg:border-r lg:border-b-gray-900">
                            Kahve fincan koleksiyonları
                        </h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:space-y-0">
                        {coffeCard.map((card, index) => (
                            <CategoryCard
                                key={index}
                                href={card.href}
                                image={card.image}
                                title={card.title}
                                buttonText={card.buttonText}
                            />
                        ))}
                    </div>
                </div>
                <SwiperCard
                    type="product"
                    cardData={casesCards}
                    header="Kentsel sanat kılıfları"
                    numberSliderPerView={[1, 2, 3]}
                    isNavigation={true}
                    isPagination={false}
                />
                <div className="justify-center">
                    <div className="flex items-center my-4 ">
                        <h1 className="[font-size:_clamp(1.3rem,1.2vw,2.5rem)] font-bold leading-[28px] border-0 lg:border-r lg:border-b-gray-900">
                            Robhisattwa ile birlikte
                        </h1>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="relative bg-black bg-opacity-50 shadow-md overflow-hidden w-100">
                            <img
                                src="https://www.binspired.com/cdn/shop/files/robhisattwa-mens-tshirt.jpg?v=1647521412&width=1152"
                                alt={robhisattwaCard[0].title}
                                className="card-image block"
                            />
                            <div className="p-4 cursor-pointer absolute inset-0 flex flex-col justify-end p-4 my-3 md:my-4 mx-4 space-y-4">
                                <h2 className="text-[24px] font-[700] leading-[21.6px] text-white mb-2">
                                    {robhisattwaCard[0].title}
                                </h2>
                                <button className="bg-white w-fit font-[600] text-black rounded-full pt-[8px] pr-[30px] pb-[8px] pl-[30px]">
                                    {robhisattwaCard[0].buttonText}
                                </button>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4 md:space-y-0">
                            {robhisattwaCard[0].smallImage.map(
                                (card, index) => (
                                    <div
                                        key={index}
                                        className="relative bg-black bg-opacity-50 shadow-md overflow-hidden w-100"
                                    >
                                        <img
                                            src={card.image}
                                            alt={robhisattwaCard[0].title}
                                            className="card-image"
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
                <SwiperCard
                    type="category"
                    cardData={groveCards}
                    header="Grooveu Keşfedin"
                    isNavigation={true}
                    numberSliderPerView={[1, 2, 3]}
                    isPagination={false}
                />
            </div>
        </FireLayout>
    );
}

export default Index;
